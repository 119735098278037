import React from 'react';
import { string } from 'prop-types';
import Link from 'gatsby-plugin-transition-link';
import styled from 'styled-components';
// utils
import { above, colors } from '../utils';

const { blackHover, whiteHover } = colors;

const Title = styled.h2`
  text-transform: uppercase;
  color: ${({ color }) => color};
  font-size: 3.125vw;
  line-height: 1em;
  text-align: center;
  grid-area: 1 / 1 / auto / 13;
  place-self: center;
  margin: 0;
  z-index: 2;
  letter-spacing: 0.035em;
  transition: color 200ms ease-out;

  &:hover {
    color: ${({ color }) =>
      color === `#fff` ? `${whiteHover}` : `${blackHover}`};
    cursor: pointer;
  }

  ${above.huge`
    font-size: 50px;
  `}
`;

const CaseLink = styled(Link)`
  text-decoration: none;
  &:hover {
    cursor: default;
  }
`;

const NextCase = styled(CaseLink)`
  z-index: 2;
  position: relative;

  img {
    grid-area: 1 / 1 / auto / 8;
  }
`;

const PrevCase = styled(CaseLink)`
  margin-top: -10%;

  img {
    grid-area: 1 / 6 / auto / 13;
  }
`;

const CaseNavSection = styled.section`
  background-color: ${({ bgColor }) => bgColor};
  width: 100vw;
  position: relative;
  padding-top: 10%;
  padding-bottom: 10%;

  ${above.huge`
    width: 1600px;
  `}
`;

const CaseStudyFooterNav = ({
  nextTitle,
  nextImg,
  nextLink,
  prevTitle,
  prevImg,
  prevLink,
  bgColor,
  textColor,
}) => {
  const convertToLink = title => `/case-studies/${title}`;

  const nextTo = convertToLink(nextLink);
  const prevTo = convertToLink(prevLink);
  return (
    <div>
      <CaseNavSection bgColor={bgColor} className="grid__container">
        <NextCase to={nextTo} className="grid">
          <img src={nextImg} alt="no tag" />
          <Title color={textColor}>{nextTitle}</Title>
        </NextCase>
        <PrevCase to={prevTo} className="grid">
          <img src={prevImg} alt="no tag" />
          <Title>{prevTitle}</Title>
        </PrevCase>
      </CaseNavSection>
    </div>
  );
};

CaseStudyFooterNav.propTypes = {
  nextTitle: string.isRequired,
  nextImg: string.isRequired,
  nextLink: string.isRequired,
  prevTitle: string.isRequired,
  prevImg: string.isRequired,
  prevLink: string.isRequired,
  bgColor: string,
  textColor: string,
};

export default CaseStudyFooterNav;
