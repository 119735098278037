import { css } from 'styled-components';

export const size = {
  small: 0,
  tablet: 768,
  desktop: 1024,
  large: 1280,
  xl: 1440,
  huge: 1600,
};

export const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label] / 16}rem) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label] / 16}rem) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

// -------------------
// example usage

// import { above } from '../utils';

// const Heading = styled.h1`
//   font-size: 2rem;
//   ${above.med`
//     color: blue;
//   `}
// `;
