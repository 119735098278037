import React from 'react';

function TheOnesSlideShow({ images }) {

  return (
    <section className="styled-scrollbar image-gallery">
      <div className="image-gallery__container">
        <div className="image-gallery__wrapper">
          {images.map((img, i) => (
            <img className="image-wrapper" src={img} key={i} alt="no tag" />
          ))}
        </div>
      </div>
    </section>
  )
}

export default TheOnesSlideShow;
