/* eslint-disable */
import React, { useState } from 'react';
import { string, bool } from 'prop-types';

function Video({ autoPlay = true, className = '', loop = true, noSound, src }) {
  const [videoMuted, setMuted] = useState(true);
  return (
    <>
      <video
        className={noSound ? `video ${className}` : `video has-sound ${className}`}
        autoPlay={autoPlay}
        loop={loop}
        muted={videoMuted}
        onClick={() => setMuted(!videoMuted)}
      >
        <source src={src} type="video/mp4" />
      </video>
      {!noSound && videoMuted && (
        <span
          className="video__sound-btn"
          onClick={() => setMuted(!videoMuted)}
        />
      )}
    </>
  );
}

Video.propTypes = {
  src: string.isRequired,
  noSound: bool,
  autoPlay: bool,
  loop: bool,
};

export default Video;
