import React from 'react';

function AutoPlayVideo({ className, poster, src, style }) {
  return (
    <>
      <video
        className={className ? `video ${className}` : `video`}
        autoPlay
        loop
        poster={poster}
        muted
        playsInline
        style={style}
      >
        <source src={src} type="video/mp4" />
      </video>
    </>
  );
}

export default AutoPlayVideo;
