/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
// components
import AutoPlayVideo from './AutoPlayVideo';
import Video from './Video';

function zoomVideo({ autoPlayVideo, src }) {
  return (
    <div id="pinContainer" className="zoom-video zoom-video--pinned">
      <Controller>
        <Scene triggerHook="onEnter" duration="100%">
          <Timeline wrapper={<div id="pinned" />}>
            <Tween from={{ css: { scale: 0.2 } }}>
              <div>
                {
                  autoPlayVideo ? (
                    <AutoPlayVideo src={src}/>
                  ) : (
                    <Video nosound src={src} />
                  )
                }
              </div>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </div>
  );
}

zoomVideo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default zoomVideo;
