import React from 'react';

function TheOnesSlideShowMobile({ images }) {

  return (
    <div className="to-mobile__scroll-gallery">
      <div className="to-mobile__scroll-gallery-wrapper">
        {images.map((img, i) => (
          <img key={i} src={img} className="to-mobile__img" />
        ))}
      </div>
    </div>
  )
}

export default TheOnesSlideShowMobile;