import React from 'react';
import { withController } from 'react-scroll-parallax';

function ParallaxImage({ alt, className, parallaxController, src }) {
  function handleLoad() {
    // updates cached values after image dimensions have loaded
    parallaxController.update();
  }

  return <img className={className} onLoad={handleLoad} src={src} alt={alt} />;
}

export default withController(ParallaxImage);
