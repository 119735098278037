import React from 'react';
// components
import { JumpmanIcon } from 'src/components/svgs';

function TheOnesHeroMobile({ images }) {
  const [toMobileImg01] = images;
  return (
    <div className="to-mobile__hero">
      <div className="to-mobile__hero-container">
        <div className="to-mobile__hero-header">
          <JumpmanIcon modifier="to-mobile__hero-logo" />
          <h1 className="to-mobile__hero-heading">"THE ONES"</h1>
          <p className="to-mobile__hero-subheading">
            Celebrating the new generation of defiant, talented individuals.
          </p>
        </div>
      </div>
      <img className="to-mobile__img to-mobile__img--1" src={toMobileImg01} />
    </div>
  )
}

export default TheOnesHeroMobile;