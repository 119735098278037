import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/the-ones.scss';
// hooks
import theOnesMedia from 'src/hooks/theOnesMedia'
import theOnesMobileMedia from 'src/hooks/theOnesMobileMedia';
import theOnesSlideshowMedia from 'src/hooks/theOnesSlideshowMedia'
import theOnesScrollSceneMedia from 'src/hooks/theOnesScrollSceneMedia'
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from '../../lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import TheOnesContent from 'src/components/the-ones/TheOnesContent';
import MobileTheOnesContent from 'src/components/the-ones/TheOnesContentMobile';

function TheOnesPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }
  const mobileImages = [];
  const images = [];
  const slideshowImages = [];
  const scrollSceneImages = [];
  const mobileMedia = theOnesMobileMedia();
  const media = theOnesMedia();
  const slideshowMedia = theOnesSlideshowMedia();
  const scrollSceneMedia = theOnesScrollSceneMedia();

  slideshowMedia.allCloudinaryMedia.edges.forEach(item => slideshowImages.push(item.node.secure_url));

  scrollSceneMedia.allCloudinaryMedia.edges.forEach(item => scrollSceneImages.push(item.node.secure_url));

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));

  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  return (
    <div>
      <SEO title="The Ones | Falkon Content" />
      <div>
        {isDesktop
          ? (
            <TheOnesContent
              images={images}
              slideshowImages={slideshowImages}
              scrollSceneImages={scrollSceneImages}
            />
          ) : (
            <MobileTheOnesContent
              images={mobileImages}
              slideshowImages={slideshowImages}
              scrollSceneImages={scrollSceneImages}
            />
          )
        }
      </div>
    </div>
  );
}

export default React.memo(TheOnesPage);
