import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
// components
import AutoPlayVideo from '../AutoPlayVideo';
import Video from '../Video';

function TheOnesScrollSceneMobile({ images, videos }) {
  const [sceneImg01, sceneImg02, sceneImg03, sceneImg04] = images;
  const [spiderverseInterviewVid, aj1MidBlack] = videos;
  // return (
  //   <div className="to-mobile__scroll-scene">
  //     <Controller>
  //       <Scene triggerHook="onLeave" duration={width} pin>
  //         <Timeline wrapper={<div id="pinContainer" ref={ref} />}>
  //           <Tween to={{ x: `-${width - minusWidth}` }}>
  //             <section className="to-mobile__scroll-wrapper">
  //               <PanelOne />
  //               <div className="to-mobile__scroll-panel to-mobile__scroll-panel--1">
  //                 <AutoPlayVideo src={spiderverseInterviewVid} />
  //               </div>
  //               <div className="to-mobile__scroll-panel to-mobile__scroll-panel--2">
  //                 <img
  //                   className="to-mobile__scroll-img to-mobile__scroll-img--1"
  //                   src={sceneImg01}
  //                   alt=""
  //                 />
  //                 <p className="to-mobile__scroll-copy to-mobile__scroll-copy--1">
  //                   <a
  //                     href="https://www.instagram.com/shameikmoore/?hl=en"
  //                     target="_blank"
  //                     rel="noopener noreferrer"
  //                   >
  //                     @shameikmoore
  //                   </a>
  //                   <br />
  //                   SPIDER VERSE X THE ONES
  //                 </p>
  //                 <img
  //                   className="to-mobile__scroll-img to-mobile__scroll-img--1"
  //                   src={sceneImg02}
  //                   alt=""
  //                 />
  //                 <img
  //                   className="to-mobile__scroll-img to-mobile__scroll-img--1"
  //                   src={sceneImg03}
  //                   alt=""
  //                 />
  //                 <a
  //                   className="to-mobile__scroll-copy to-mobile__scroll-copy--2"
  //                   href="https://air.jordan.com/card/introducing-the-ones/"
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                 >
  //                   Home of “The Ones” : The Air Jordan Blog
  //                 </a>
  //               </div>
  //               <div className="to-mobile__scroll-panel to-mobile__scroll-panel--3" ref={minusRef}>
  //                 <img className="to-mobile__scroll-img to-mobile__scroll-img--4" src={sceneImg04} alt="" />
  //                 <div className="to-mobile__scroll-video">
  //                   <Video src={aj1MidBlack} />
  //                 </div>
  //               </div>
  //             </section>
  //           </Tween>
  //         </Timeline>
  //       </Scene>
  //     </Controller>
  //   </div>
  // )
  const [width, setWidth] = useState(0);
  const [minusWidth, setMinusWidth] = useState(0);
  const ref = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setWidth(width + w);
    }
  }, []);

  const minusRef = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setMinusWidth(w);
    }
  }, []);
  return (
    <HozStyled>
      <Controller>
        <Scene triggerHook="onLeave" duration={width} pin>
          <Timeline wrapper={<div id="pinContainer" ref={ref} />}>
            <Tween to={{ x: `-${width - minusWidth}` }}>
              <section className="horizontal-wrapper">
                <Panel1>
                  <AutoPlayVideo src={spiderverseInterviewVid} />
                </Panel1>
                <Panel2>
                  <Panel2Img1>
                    <img src={sceneImg01} alt="" />
                    <p className="caption">
                      <a
                        href="https://www.instagram.com/shameikmoore/?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        @shameikmoore
                      </a>
                      <br />
                      SPIDER VERSE X THE ONES
                    </p>
                  </Panel2Img1>
                  <Panel2Img2>
                    <img src={sceneImg02} alt="" />
                  </Panel2Img2>
                  <Panel2Img3>
                    <img src={sceneImg03} alt="" />
                    <p className="caption" style={{ textTransform: 'uppercase' }}>
                      <a
                        href="https://air.jordan.com/card/introducing-the-ones/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Home of “The Ones” : The Air Jordan Blog
                      </a>
                    </p>
                  </Panel2Img3>
                </Panel2>
                <div className="panel panel--last" ref={minusRef}>
                  <img className="last-image" src={sceneImg04} alt="" />
                  <div className="last-video">
                    <Video className="last-video__wrapper" src={aj1MidBlack} />
                  </div>
                </div>
              </section>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </HozStyled>
  )
}

const HozStyled = styled.div`
  overflow: hidden;
  position: absolute;
  top: 3524px;

  #pinContainer {
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    .horizontal-wrapper {
      display: flex;
    }

    .panel {
      flex: 0 0 auto;
      text-align: center;
      width: 100vw;
      height: 100vh;
    }

    .panel--last {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .last-image {
      width: 100vw;
      height: auto;
    }

    .last-video {
      height: 42vw;
      overflow: hidden;
      position: absolute;
      width: 64vw;
    }

    .last-video__wrapper {
      top: -22%;
    }
  }
`;

const Panel1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 100vw;
  height: 100vh;

  video {
    object-fit: cover;
    width: 100vw;
    height: auto;
  }
`;

const Panel2 = styled.div`
  display: grid;
  align-items: center;
  grid-auto-columns: ${100 * (80 / 1600)}vw;
  grid-column-gap: ${100 * (20 / 1600)}vw;
  height: 100vh;
`;

const Panel2Img1 = styled.div`
  grid-area: 1 / 2 / auto / 7;
  align-self: center;

  .caption {
    margin: 0;
    line-height: 1.2;
  }
`;

const Panel2Img2 = styled.div`
  grid-area: 1 / 6 / auto / 9;
  z-index: 2;
  align-self: center;
  transform: translateY(15%);
`;

const Panel2Img3 = styled.div`
  grid-area: 1 / 9 / auto / 18;
  z-index: 2;
  align-self: center;
  margin-left: ${100 * (32 / 1600)}vw;
  margin-right: ${100 * (48 / 1600)}vw;
`;

export default TheOnesScrollSceneMobile;