import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import ScrollScene from 'src/components/the-ones/TheOnesScrollScene';
import SlideShow from 'src/components/the-ones/TheOnesSlideShow';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import ParallaxVideo from 'src/components/parallax/ParallaxVideo';
import SectionTrigger from 'src/components/SectionTrigger';
import { JumpmanIcon } from 'src/components/svgs';
import VideoPlayer from 'src/components/VideoPlayer';
import ZoomVideo from 'src/components/ZoomVideo';
import CaseStudyNav from 'src/components/CaseStudyFooterNav';

function TheOnesContent({ images, slideshowImages, scrollSceneImages }) {
  const [
    elaineZhong,
    aj1,
    anthem,
    jamalAdams,
    elaineZhong2,
    social2,
    social3,
    social4,
    thePlaygroundFeatured,
    unbannedFeatured,
  ] = images;
  const billieEnglishVid =
    'https://player.vimeo.com/external/433467706.sd.mp4?s=de2db8e210ef2adad1317dcc1bb1791502b366ee&profile_id=164&oauth2_token_id=1365998561';
  const anthemVid =
    'https://player.vimeo.com/external/433467697.hd.mp4?s=58e81ca1e8176c43b0a1342f9824cde4c6b0c00d&profile_id=174&oauth2_token_id=1365998561';
  const roxBrownVid =
    'https://player.vimeo.com/external/433467750.sd.mp4?s=d44cf4ba07ed882f7d2017eea2e8af66f850a05d&profile_id=165&oauth2_token_id=1365998561';
  const billieEnglishVid1 =
    'https://player.vimeo.com/external/433467709.sd.mp4?s=77ed960f8790a490230b30db86f0489b253a56af&profile_id=164&oauth2_token_id=1365998561';
  const nigelSylvesterVid =
    'https://player.vimeo.com/external/433467762.sd.mp4?s=e42a177488315712b5ed8411b527ab19deb0dfd2&profile_id=165&oauth2_token_id=1365998561';
  const jalenRamseyVid =
    'https://player.vimeo.com/external/433467768.sd.mp4?s=24c3a6ecec27ec53bf8322ec0cbd6c3e4d9e9d86&profile_id=165&oauth2_token_id=1365998561';
  const elaineZhongVid =
    'https://player.vimeo.com/external/433467724.sd.mp4?s=f3632267be9c9404bd27f8361d4ce2f5f467cc95&profile_id=165&oauth2_token_id=1365998561';
  const nigelSylvesterVid1 =
    'https://player.vimeo.com/external/461689620.hd.mp4?s=af1e96f6dbe848b788c771f42b69fcc991920822&profile_id=170&oauth2_token_id=1365998561';

  return (
    <div>
      <div className="the-ones">
        <SectionTrigger bgColor="#870f0f" textColor="#fff">
          <BlockOne images={[elaineZhong, aj1]} videos={[billieEnglishVid]} />
        </SectionTrigger>
        <SectionTrigger bgColor="#870f0f" textColor="#fff">
          <ZoomVideo src={anthemVid} />
        </SectionTrigger>
        <SectionTrigger bgColor="#507d9b" textColor="#000">
          <BlockTwo images={[anthem, jamalAdams, elaineZhong2]} />
        </SectionTrigger>
        <SectionTrigger bgColor="#507d9b" textColor="#000">
          <BlockThree slideshowImages={slideshowImages} />
        </SectionTrigger>
        <SectionTrigger bgColor="#aed1d8" textColor="#fff">
          <BlockFour
            videos={[
              roxBrownVid,
              nigelSylvesterVid,
              elaineZhongVid,
              billieEnglishVid1,
              jalenRamseyVid,
            ]}
          />
        </SectionTrigger>
        <SectionTrigger bgColor="#aed1d8" textColor="#000">
          <ScrollScene images={scrollSceneImages} />
        </SectionTrigger>
        <SectionTrigger bgColor="#aed1d8" textColor="#000">
          <BlockFive videos={[nigelSylvesterVid1]} />
        </SectionTrigger>
        <SectionTrigger bgColor="#f08f7c" textColor="#000">
          <BlockSix images={[social2, social3, social4]} />
        </SectionTrigger>
        <SectionTrigger bgColor="#f08f7c" textColor="#fff">
          <CaseStudyNav
            nextTitle="The Playground"
            nextImg={thePlaygroundFeatured}
            nextLink="the-playground"
            prevTitle="Unbanned"
            prevImg={unbannedFeatured}
            prevLink="unbanned"
          />
        </SectionTrigger>
      </div>
    </div>
  )
}

function BlockOne({ images, videos }) {
  const [elaineZhong, aj1] = images;
  const [billieEnglishVid] = videos;
  return (
    <div className="container container--small the-ones__block the-ones__block--1">
      <div className="the-ones__hdg">
        <JumpmanIcon modifier="the-ones__hdg-logo" />
        <h3 className="main-heading">"The Ones"</h3>
        <p className="main-copy">
          Celebrating the new generation of defiant,
          <br />
          talented individuals.
        </p>
      </div>

      <div className="the-ones__body the-ones__body--1">
        <h3 className="main-heading main-heading--right">Challenge</h3>
        <p className="main-copy">
          How do you celebrate a shoe
          <br />
          that has such cultural impact
          <br />
          that it can no longer be
          <br />
          connected to any one
          <br />
          individual but an entire
          <br />
          cultural movement?
        </p>
      </div>
      <div className="the-ones__img the-ones__img--ez">
        <img src={elaineZhong} alt="elaine zhong" />
      </div>
      <div className="the-ones__img the-ones__img--aj1">
        <img src={aj1} alt="aj1" />
      </div>
      <Parallax className="the-ones__video the-ones__video--be" y={[20, -20]}>
        <ParallaxVideo muted src={billieEnglishVid} />
      </Parallax>
    </div>
  );
}

function BlockTwo({ images }) {
  const [anthem, jamalAdams, elaineZhong2] = images;
  return (
    <div className="container container--small the-ones__block the-ones__block--2">
      <div className="the-ones__body the-ones__body--2">
        <h3 className="main-heading main-heading--solution">Solution</h3>
        <p className="main-copy">
          Build a campaign rooted in
          <br />
          tradition yet connected to
          <br />
          the defiant culture-shapers
          <br />
          of today.
        </p>
        <blockquote className="blockquote">
          &lsquo;The Ones&rsquo;
          <br />
          Celebrates A New
          <br />
          Generation Of Defiant,
          <br />
          Talented Individuals.
        </blockquote>
      </div>
      <Parallax className="the-ones__img the-ones__img--anthem" y={[20, -20]}>
        <img src={anthem} alt="anthem" />
        <a
          className="main-copy"
          href="https://www.instagram.com/brianaking/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @brianaking
        </a>
      </Parallax>
      <div className="the-ones__img the-ones__img--ja" y={[20, -20]}>
        <img src={jamalAdams} alt="jamal adams" />
      </div>
      <div className="the-ones__img the-ones__img--ez2">
        <img src={elaineZhong2} alt="elaine zhong" />
        <a
          className="main-copy"
          href="https://www.instagram.com/elaine_zhongchuxi/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @elaine_zhongchuxi
        </a>
      </div>
    </div>
  );
}

function BlockThree({ slideshowImages }) {
  return (
    <div className="the-ones__block the-ones__block--3">
      <div className="container container--small">
        <p className="gallery-title">Lookbook</p>
      </div>
      <SlideShow images={slideshowImages} />
      <div className="container container--small">
        <span className="scroll-arrow scroll-arrow--right" />
      </div>
    </div>
  );
}

function BlockFour({ videos }) {
  const [
    roxBrownVid,
    nigelSylvesterVid,
    elaineZhongVid,
    billieEnglishVid1,
    jalenRamseyVid,
  ] = videos;
  return (
    <div className="container the-ones__block the-ones__block--4">
      <div className="the-ones__body the-ones__body--4">
        <h3 className="main-heading main-heading--execution main-heading--center">
          Execution
        </h3>
        <p className="main-copy">
          The talented cast
          <br />
          who represent “The Ones” this
          <br />
          season: Nigel Sylvester, Billie
          <br />
          Eilish, YBN Nahmir, Rox
          <br />
          Brown, Elaine Zhong, Kori
          <br />
          Carter, Jalen Ramsey, Briana
          <br />
          King, Jamal Adams and
          <br />
          Shameik Moore.
        </p>
        <p className="main-copy">
          Jordan Brand brought the
          <br />
          studio to the court,
          <br />
          combining sport + culture +
          <br />
          community. 
        </p>
      </div>
      <Parallax className="the-ones__video--vertical" y={[20, -20]}>
        <div className="the-ones__video the-ones__video--rox">
          <ParallaxVideo muted src={roxBrownVid} />
          <a
            className="main-copy"
            href="https://www.instagram.com/rox_brown/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            @rox_brown
          </a>
        </div>

        <div className="the-ones__video the-ones__video--nigel">
          <ParallaxVideo muted src={nigelSylvesterVid} />
          <a
            className="main-copy"
            href="https://www.instagram.com/nigelsylvester/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            @nigelsylvester
          </a>
        </div>
        <div className="the-ones__video the-ones__video--ez">
          <ParallaxVideo muted src={elaineZhongVid} />
          <a
            className="main-copy"
            href="https://www.instagram.com/elaine_zhongchuxi/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            @elaine_zhongchuxi
          </a>
        </div>
      </Parallax>
      <div className="video-wrapper the-ones__video the-ones__video--be1">
        <VideoPlayer muted src={billieEnglishVid1} />
        <a
          className="main-copy"
          href="https://www.instagram.com/billieeilish/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @billieeilish
        </a>
      </div>
      <div className="video-wrapper the-ones__video the-ones__video--jr">
        <VideoPlayer muted src={jalenRamseyVid} />
        <a
          className="main-copy"
          href="https://www.instagram.com/jalenramsey/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @jalenramsey
        </a>
      </div>
    </div>
  );
}

function BlockFive({ videos }) {
  const [nigelSylvesterVid1] = videos;
  return (
    <div className="container container--small the-ones__block the-ones__block--5">
      <div className="video-wrapper the-ones__video the-ones__video--nigel1">
        <VideoPlayer
          className="global-box-shadow"
          muted
          src={nigelSylvesterVid1}
        />
        <p className="main-copy">NIGEL’S SHOP-THE-ONES PAGE</p>
      </div>
    </div>
  );
}

function BlockSix({ images }) {
  const [social2, social3, social4] = images;
  return (
    <div className="container container--small the-ones__block the-ones__block--6">
      <div className="the-ones__body the-ones__body--6">
        <h3 className="main-heading main-heading--impact">Impact</h3>
        <h3 className="main-heading main-heading--impact">Impact</h3>
        <p className="main-copy">
          The on-going campaign
          <br />
          turned franchise lives across
          <br />
          all brand and authenticator
          <br />
          channels resulting in millions
          <br />
          of eyeballs with key story
          <br />
          telling about each
          <br />
          authenticator on the Air
          <br />
          Jordan Blog.
        </p>
      </div>
      <Parallax
        className="the-ones__img the-ones__img--social the-ones__img--social2"
        y={[20, -20]}
      >
        <ParallaxImage
          className="global-box-shadow"
          src={social2}
          alt="social media post"
        />
      </Parallax>
      <Parallax
        className="the-ones__img the-ones__img--social the-ones__img--social3"
        y={[20, -20]}
      >
        <ParallaxImage
          className="global-box-shadow"
          src={social3}
          alt="social media post"
        />
      </Parallax>
      <Parallax
        className="the-ones__img the-ones__img--social the-ones__img--social4"
        y={[20, -20]}
      >
        <ParallaxImage
          className="global-box-shadow"
          src={social4}
          alt="social media post"
        />
      </Parallax>
    </div>
  );
}

export default TheOnesContent;