import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import SectionTrigger from 'src/components/SectionTrigger';
import AutoPlayVideoPlayer from 'src/components/AutoPlayVideo';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import ParallaxVideo from 'src/components/parallax/ParallaxVideo';
import { Arrow } from 'src/components/svgs'
import MobileHero from './TheOnesHeroMobile';
import MobileSlideshow from './TheOnesSlideShowMobile';
import MobileScrollScene from './TheOnesScrollSceneMobile';


function TheOnesContentMobile({ images, slideshowImages, scrollSceneImages }) {
  const scrollOffset = [40, -40];
  // const scrollOffset = [0,0];
  const [
    toMobileImg01,
    toMobileImg02,
    toMobileImg03,
    toMobileImg04,
    toMobileImg05,
    toMobileImg06,
    toMobileImg07,
    toMobileImg08,
    toMobileImg09,
  ] = images;
  const anthemVid = 'https://player.vimeo.com/external/433467697.hd.mp4?s=58e81ca1e8176c43b0a1342f9824cde4c6b0c00d&profile_id=174&oauth2_token_id=1365998561';
  const roxBrownVid = 'https://player.vimeo.com/external/433467750.sd.mp4?s=d44cf4ba07ed882f7d2017eea2e8af66f850a05d&profile_id=165&oauth2_token_id=1365998561';
  const billieEnglishVid1 = 'https://player.vimeo.com/external/433467709.sd.mp4?s=77ed960f8790a490230b30db86f0489b253a56af&profile_id=164&oauth2_token_id=1365998561';
  const nigelSylvesterVid = 'https://player.vimeo.com/external/433467762.sd.mp4?s=e42a177488315712b5ed8411b527ab19deb0dfd2&profile_id=165&oauth2_token_id=1365998561';
  const jalenRamseyVid = 'https://player.vimeo.com/external/433467768.sd.mp4?s=24c3a6ecec27ec53bf8322ec0cbd6c3e4d9e9d86&profile_id=165&oauth2_token_id=1365998561';
  const elaineZhongVid = 'https://player.vimeo.com/external/433467724.sd.mp4?s=f3632267be9c9404bd27f8361d4ce2f5f467cc95&profile_id=165&oauth2_token_id=1365998561';
  const spiderverseVid = 'https://player.vimeo.com/external/433467733.sd.mp4?s=20e9ef04989f36e73f6f9ce445206da8d3c9203f&profile_id=165&oauth2_token_id=1365998561';
  const aj1MidBlack =
  'https://player.vimeo.com/external/474934644.hd.mp4?s=769f1493997427073eb84d78ed9388b03cd1ac06&profile_id=174&oauth2_token_id=1365998561';

  return (
    <div className="to-mobile">
      <SectionTrigger bgColor="#870f0f">
        <MobileHero images={[toMobileImg01]} />
      </SectionTrigger>
      <div className="to-mobile__container to-mobile__container--1">
        <img src={toMobileImg02} className="to-mobile__img to-mobile__img--2" />
        <Parallax className="to-mobile__img to-mobile__img--3" y={scrollOffset}>
          <ParallaxImage src={toMobileImg03} />
        </Parallax>
        <div className="to-mobile__heading to-mobile__heading--1">
          <h2 className="challenge">Challenge</h2>
        </div>
        <p className="to-mobile__copy to-mobile__copy--1">
          How do you celebrate a shoe that has such cultural impact that it can no longer be connected to any one individual but an entire cultural movement?
        </p>
        <div className="to-mobile__heading to-mobile__heading--2">
          <h2 className="solution">Solution</h2>
        </div>
        <p className="to-mobile__copy to-mobile__copy--2">
          Build a campaign rooted in tradition yet connected to the defiant culture-shapers of today.
        </p>
        <AutoPlayVideoPlayer src={anthemVid} className="to-mobile__video to-mobile__video--1" />
        <Parallax className="to-mobile__img to-mobile__img--4" y={scrollOffset}>
          <ParallaxImage src={toMobileImg04} />
        </Parallax>
        <img src={toMobileImg05} className="to-mobile__img to-mobile__img--5" />
        <img src={toMobileImg06} className="to-mobile__img to-mobile__img--6" />
        <div className="to-mobile__blockquote to-mobile__blockquote--1">
          <blockquote>
            "THE ONES" CELEBRATES A NEW GENERATION OF DEFIANT, TALENTED INDIVIDUALS.
          </blockquote>
        </div>
        <p className="to-mobile__tagline to-mobile__tagline--1">
          Lookbook
        </p>
        <MobileSlideshow images={slideshowImages} />
        <Arrow modifier="to-mobile__scroll-gallery-arrow" />
        <div className="to-mobile__heading to-mobile__heading--3">
          <h2 className="execution">Execution</h2>
        </div>
        <p className="to-mobile__copy to-mobile__copy--3">
          The talented cast who represent “The Ones” this season: Nigel Sylvester, Billie Eilish, YBN Nahmir, Rox Brown, Elaine Zhong, Kori Carter, Jalen Ramsey, Briana King, Jamal Adams and Shameik Moore.
        </p>
        <AutoPlayVideoPlayer src={roxBrownVid} className="to-mobile__video to-mobile__video--2" />
        <a
          className="to-mobile__tagline to-mobile__tagline--2"
          href="https://www.instagram.com/rox_brown/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @rox_brown
        </a>
        <AutoPlayVideoPlayer src={nigelSylvesterVid} className="to-mobile__video to-mobile__video--4" />
        <a
          className="to-mobile__tagline to-mobile__tagline--4"
          href="https://www.instagram.com/nigelsylvester/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @nigelsylvester
        </a>
        <AutoPlayVideoPlayer src={elaineZhongVid} className="to-mobile__video to-mobile__video--6" />
        <a
          className="to-mobile__tagline to-mobile__tagline--6"
          href="https://www.instagram.com/elaine_zhongchuxi/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @elaine_zhongchuxi
        </a>

        <div className="to-mobile__video--vertical" y={scrollOffset}>
          <AutoPlayVideoPlayer className="to-mobile__video to-mobile__video--3" src={billieEnglishVid1} />
          <a
            className="to-mobile__tagline to-mobile__tagline--3"
            href="https://www.instagram.com/billieeilish/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            @billieeilish
          </a>
          <AutoPlayVideoPlayer className="to-mobile__video to-mobile__video--5" src={jalenRamseyVid} />
          <a
            className="to-mobile__tagline to-mobile__tagline--5"
            href="https://www.instagram.com/jalenramsey/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            @jalenramsey
          </a>
        </div>
      </div>

      <MobileScrollScene
        images={scrollSceneImages}
        videos={[spiderverseVid, aj1MidBlack]}
      />

      <div className="to-mobile__container to-mobile__container--2">
        <img src={toMobileImg07} className="global-box-shadow to-mobile__img to-mobile__img--7" />
        <img src={toMobileImg08} className="global-box-shadow to-mobile__img to-mobile__img--8" />
        <img src={toMobileImg09} className="global-box-shadow to-mobile__img to-mobile__img--9" />
        <div className="to-mobile__heading to-mobile__heading--4">
          <h2 className="impact">Impact</h2>
          <h2 className="impact">Impact</h2>
        </div>
        <p className="to-mobile__copy to-mobile__copy--4">
          The on-going campaign lives across all brand and authenticator channels result-ing in millions of eyeballs with deeper storytelling on the Air Jordan Blog.
        </p>
      </div>
      <SectionTrigger
        bgColor="#870f0f"
        className="to-mobile__section to-mobile__section--1"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#507d9b"
        className="to-mobile__section to-mobile__section--2"
        textColor="#000"
      />
      <SectionTrigger
        bgColor="#aed1d8"
        className="to-mobile__section to-mobile__section--3"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#aed1d8"
        className="to-mobile__section to-mobile__section--4"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#f08f7c"
        className="to-mobile__section to-mobile__section--5"
        textColor="#000"
      />
    </div>
  )
}

export default TheOnesContentMobile;