import React from 'react';
import { withController } from 'react-scroll-parallax';
import AutoPlayVideo from '../AutoPlayVideo';
import VideoPlayer from '../VideoPlayer';

// eslint-disable-next-line react/prop-types
function ParallaxVideo({ autoPlayVideo, className, parallaxController, poster, src }) {

  function handleLoad() {
    // updates cached values after image dimensions have loaded
    // eslint-disable-next-line react/prop-types
    parallaxController.update();
  }

  if (autoPlayVideo) {
    return (
       <AutoPlayVideo
         className={className}
         onLoad={handleLoad}
         poster={poster}
         src={src}
       />
    )
  } else {
    return (
      <VideoPlayer
        className={className}
        onLoad={handleLoad}
        poster={poster}
        src={src}
      />
    )
  }
}

export default withController(ParallaxVideo);
