import { createGlobalStyle } from 'styled-components';
import { above, size } from './breakpoints';
import { colors } from './colors';

const { blackHover } = colors;

export const GlobalStyle = createGlobalStyle`
  html {
    font: 112.5%/1.45em  'Graphik Wide Web', sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;

    *, *:before, *:after {
      box-sizing: inherit;
    }
  }

  body {
    margin: 0;
  }

  /* Feel free to add any global styles here */
  nav, h1, h2, h3 {
    font-weight:  900;
    letter-spacing: 0.025em;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    font-size: 1.25vw;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.035em;

    ${above.huge`
      font-size: 20px;
    `}

    &.gallery-title {
      font-weight: 900;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    &.caption {
      position: relative;
      z-index: 2;
    }
  }

  video {
    display: block;
  }

  img, svg {
    display: block;
    max-width: 100%;
  }

  blockquote {
    margin: 0;
  }

  .global-box-shadow {
    box-shadow: ${(35 / 1600) * 100}vw ${(50 / 1600) * 100}vw ${(2 / 1600) *
  100}vw rgba(0, 0, 0, 0.34);

    ${above.huge`
      box-shadow: ${(35 / 1600) * size.huge}px ${(50 / 1600) *
      size.huge}px ${(2 / 1600) * size.huge}px rgba(0, 0, 0, 0.34);
    `}
  }

  .grid-container {
    margin: 0 auto;
    padding: 0 13.75%;
    width: 100%;

    ${above.huge`
      max-width: ${size.huge}px;
    `}
  }

  .row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
  }

  .styled-scrollbar::-webkit-scrollbar {
    width: 1em;
  }

  .styled-scrollbar::-webkit-scrollbar-track {
    box-shadow: none;
  }

  .styled-scrollbar::-webkit-scrollbar-thumb {
    background-color: ${blackHover}CC;
    outline: none;
  }

  .styled-scrollbar--blend::-webkit-scrollbar-thumb {
    mix-blend-mode: lighten;
  }

  .main-heading {
    font-size: ${(60 / 1600) * 100}vw;
    font-weight: 900;
    letter-spacing: 0.025em;
    line-height: 1;
    margin: 0.5em 0;
    text-transform: uppercase;

    ${above.huge`
      font-size: ${(60 / 1600) * size.huge}px;
    `}
  }

  .project-logo {
    height: ${(60 / 1600) * 100}vw;
    margin: 0 auto;

    ${above.huge`
      height: ${(60 / 1600) * size.huge}px;
    `}
  }

  blockquote {
    text-align: center;

    p {
      font-size: ${(100 / 1600) * 100}vw;
      line-height: 1;
      margin: 0 0 0.5em;
      font-family: 'Moret';
      text-transform: uppercase;
      letter-spacing: 0;

      ${above.huge`
        font-size: ${(100 / 1600) * size.huge}px;
      `}
    }

    cite {
      font-size: ${(16 / 1600) * 100}vw;
      line-height: 1;
      letter-spacing: 0.1em;
      margin: 0;
      font-weight: 900;
      text-transform: uppercase;
      font-style: normal;

      ${above.huge`
        font-size: ${(16 / 1600) * size.huge}px;
      `}
    }
  }

  .zoom-section {
    overflow: hidden;
    position: relative;
  }

  .pin-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > div {
      flex: 0 0 auto;
      width: 100%;
    }
  }
`;
