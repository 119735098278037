import React, { useRef, useState, useEffect } from 'react';
import { PlayIcon } from '../components/svgs';

const isBrowserSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

function Video({ className, poster, src }) {
  const videoRef = useRef(null);
  const [videoPlayback, setVideoPlayback] = useState(false);
  const [activeClass, setActiveClass] = useState(false);

  const handleClick = () => {
    // TODO: toggle active class to hide/show button
    // setVideoPlayback(!videoPlayback);
    setActiveClass(!activeClass);
    handlePlayback();
  };

  const handlePlayback = () => {
    const isSafari = isBrowserSafari();
    // obtain reference to the video element
    const player = videoRef.current;
    setVideoPlayback(!videoPlayback);

    if (isSafari && player) {

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.load();
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute('poster', poster);
        player.setAttribute('preload', 'none');
        player.setAttribute('muted', ''); // leave no stones unturned :)

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                // player.load();
                // player.style.display = "none";
                player.setAttribute('poster', poster);
                player.setAttribute('preload', 'none');
              });
          }
        }, 0);
      }
    } else if (player) {
      player.play();
    }
  }

  useEffect(() => {
    if (videoPlayback) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
      videoRef.current.load();
    }
  },[videoPlayback],[activeClass]);

  return (
    <div
      className={className ? `video-player ${className}` : 'video-player'}
      onClick={() => handleClick()}
    >
      <video
        ref={videoRef}
        poster={poster || null}
        playsInline
        src={src}
      >
        <source src={src} type="video/mp4" />
      </video>
      <button
        type="button"
        className={activeClass ? `button is-playing` : `button`}
      >
        <PlayIcon />
      </button>
    </div>
  );
}

export default Video;