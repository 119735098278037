function handleParallaxCache(parallaxController, transitionStatus) {
  const $tlWrap =
    typeof window !== 'undefined' &&
    window.document.querySelector('.tl-wrapper');

  if ($tlWrap && transitionStatus === 'entered') {
    window.setTimeout(() => {
      parallaxController.update();
    }, 500);
  }
}

export default handleParallaxCache;
