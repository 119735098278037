import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
// components
import AutoPlayVideo from '../AutoPlayVideo';
import Video from '../Video';
// utils
import { above, size } from 'src/utils';
// video assets
import spiderverseInterviewVid from 'src/videos/the-ones/14.1920x1080_JB_SpiderVerse_Interview_30_181128.mp4';

const HozStyled = styled.div`
  overflow: hidden;

  #pinContainer {
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    .horizontal-wrapper {
      display: flex;
    }

    .panel {
      flex: 0 0 auto;
      text-align: center;
      width: 100vw;
      height: 100vh;
    }

    .panel--last {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .last-image {
      width: 100vw;
      height: auto;

      ${above.large`
        width: ${size.large}px;
        height: ${size.large / (16 / 9)}px;
      `}

      ${above.xl`
        width: ${size.xl}px;
        height: ${size.xl / (16 / 9)}px;
      `}

      ${above.huge`
        width: ${size.huge}px;
        height: ${size.huge / (16 / 9)}px;
      `}
    }

    .last-video {
      height: 42vw;
      overflow: hidden;
      position: absolute;
      width: 64vw;

      ${above.large`
        height: 520px;
        width: 810px;
      `}

      ${above.xl`
        height: 630px;
        width: 936px;
      `}

      ${above.huge`
        height: 654px;
        width: 1008px;
      `}
    }

    .last-video__wrapper {
      top: -22%;
    }
  }
`;

const Panel1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 100vw;
  height: 100vh;

  video {
    object-fit: cover;
    width: 100vw;
    height: auto;

    ${above.large`
      width: ${size.large}px;
      height: ${size.large / (16 / 9)}px;
    `}

    ${above.xl`
      width: ${size.xl}px;
      height: ${size.xl / (16 / 9)}px;
    `}

    ${above.huge`
      width: ${size.huge}px;
      height: ${size.huge / (16 / 9)}px;
    `}
  }
`;

const Panel2 = styled.div`
  display: grid;
  align-items: center;
  grid-auto-columns: ${100 * (80 / 1600)}vw;
  grid-column-gap: ${100 * (20 / 1600)}vw;
  height: 100vh;

  ${above.huge`
    grid-auto-columns: 80px;
    grid-column-gap: 20px;
  `}
`;

const Panel2Img1 = styled.div`
  grid-area: 1 / 2 / auto / 7;
  align-self: center;

  .caption {
    margin: 0;
    line-height: 1.2;
  }
`;

const Panel2Img2 = styled.div`
  grid-area: 1 / 6 / auto / 9;
  z-index: 2;
  align-self: center;
  transform: translateY(15%);
`;

const Panel2Img3 = styled.div`
  grid-area: 1 / 9 / auto / 18;
  z-index: 2;
  align-self: center;
  margin-left: ${100 * (32 / 1600)}vw;
  margin-right: ${100 * (48 / 1600)}vw;

  ${above.huge`
    margin-left: 32px;
    margin-right: 48px;
  `}
`;

function TheOnesScrollGallery({ images }) {
  const [spiderverseProduct, spiderverseInterview, theonesblog, web17] = images;

  const aj1MidBlack =
    'https://player.vimeo.com/external/474934644.hd.mp4?s=769f1493997427073eb84d78ed9388b03cd1ac06&profile_id=174&oauth2_token_id=1365998561';

  const [width, setWidth] = useState(0);
  const [minusWidth, setMinusWidth] = useState(0);
  const ref = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setWidth(width + w);
    }
  }, []);

  const minusRef = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setMinusWidth(w);
    }
  }, []);
  return (
    <HozStyled>
      <Controller>
        <Scene triggerHook="onLeave" duration={width} pin>
          <Timeline wrapper={<div id="pinContainer" ref={ref} />}>
            <Tween to={{ x: `-${width - minusWidth}` }}>
              <section className="horizontal-wrapper">
                <PanelOne />
                <PanelTwo
                  images={[
                    spiderverseProduct,
                    spiderverseInterview,
                    theonesblog,
                  ]}
                />
                <PanelThree minusRef={minusRef} images={[web17]} videos={[aj1MidBlack]} />
              </section>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </HozStyled>
  );
}

function PanelOne() {
  return (
    <Panel1>
      <AutoPlayVideo src={spiderverseInterviewVid} />
    </Panel1>
  );
}

// eslint-disable-next-line react/prop-types
function PanelTwo({ images }) {
  const [img01, img02, img03] = images;
  return (
    <Panel2>
      <Panel2Img1>
        <img src={img01} alt="no tag" />
        <p className="caption">
          <a
            href="https://www.instagram.com/shameikmoore/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            @shameikmoore
          </a>
          <br />
          SPIDER VERSE X THE ONES
        </p>
      </Panel2Img1>
      <Panel2Img2>
        <img src={img02} alt="no tag" />
      </Panel2Img2>
      <Panel2Img3>
        <img src={img03} alt="no tag" />
        <p className="caption" style={{ textTransform: 'uppercase' }}>
          <a
            href="https://air.jordan.com/card/introducing-the-ones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Home of “The Ones” : The Air Jordan Blog
          </a>
        </p>
      </Panel2Img3>
    </Panel2>
  );
}

// eslint-disable-next-line react/prop-types
function PanelDos({ images }) {
  const [img01, img02, img03] = images;
  return (
    <div className="panel-2">
      <div className="panel-2__wrap">
        <div className="panel-2__img-wrap">
          <img
            className="panel-2__img panel-2__img--1"
            src={img01}
            alt="no tag"
          />
          <p className="caption">
            <a
              className="caption-link"
              href="https://www.instagram.com/shameikmoore/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              @shameikmoore
            </a>
            <br />
            SPIDER VERSE X THE ONES
          </p>
        </div>
        <div className="panel-2__img-wrap">
          <img
            className="panel-2__img panel-2__img--2"
            src={img02}
            alt="no tag"
          />
        </div>
        <div className="panel-2__img-wrap">
          <img
            className="panel-2__img panel-2__img--3"
            src={img03}
            alt="no tag"
          />
          <p className="caption">
            <a
              href="https://air.jordan.com/card/introducing-the-ones/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Home of “The Ones” : The Air Jordan Blog
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
function PanelThree({ minusRef, images, videos }) {
  const [img01] = images;
  const [aj1MidBlack] = videos;
  return (
    <div className="panel panel--last" ref={minusRef}>
      <img className="last-image" src={img01} alt="no tag" />
      <div className="last-video">
        <Video className="last-video__wrapper" src={aj1MidBlack} />
      </div>
    </div>
  );
}

export default TheOnesScrollGallery;
