/**
 * Clear Transition Props
 * @param {obj} DOM el / ref
 * @param {obj} transitionStatus
 */
function clearTransitionProps(transitionStatus) {
  const $tlWrap =
    typeof window !== 'undefined' &&
    window.document.querySelector('.tl-wrapper');

  if ($tlWrap && transitionStatus === 'entered') {
    window.setTimeout(() => ($tlWrap.style.transform = ''), 1500);
  }
}

export default clearTransitionProps;
